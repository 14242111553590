<template>
    <div class="col-12 col-lg-4 offset-lg-4">
        <div class="input-icon position-relative">
            <svg class="position-absolute top-50 start-0 translate-middle-y ms-3" height="24" width="24.005"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.25 9.75a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 15 0Zm-1.448 7.645A9.703 9.703 0 0 1 9.75 19.5 9.749 9.749 0 0 1 0 9.75 9.749 9.749 0 0 1 9.75 0a9.749 9.749 0 0 1 9.75 9.75 9.703 9.703 0 0 1-2.105 6.052l5.813 5.812.797.797L22.41 24l-.797-.797-5.812-5.812v.004Z"
                    fill="#373741"
                    fill-rule="nonzero"/>
            </svg>

            <input v-model="search" aria-label="Türmodell Suchfeld"
                   class="form-control form-control-lg border-white ps-5"
                   placeholder="Türmodell finden"
                   type="text">

            <ul v-if="suggestions.length > 0" class="dropdown-menu border-light py-0 w-100 show">
                <li><small class="dropdown-header">Das haben wir gefunden</small></li>
                <li v-for="suggestion in suggestions">
                    <a :href="suggestion.siteUrl" class="dropdown-item">{{ suggestion.label }}</a>
                </li>
            </ul>

        </div>
    </div>
</template>

<script lang="ts" setup>

import axios from "axios";
import {type Ref, ref, watch} from "vue";

const props = defineProps({
    apiUrl: {type: String, required: true}
});

const search: Ref<string> = ref('');
const suggestions: Ref<Array<Object>> = ref([]);

watch(search, newValue => {
    if (newValue.length > 2) {
        axios.get(props.apiUrl, {
            params: {
                phrase: newValue
            }
        })
            .then(response => {
                suggestions.value = response.data;
            });
    } else {
        suggestions.value = [];
    }
});

</script>
